import { Headline, Text, Check } from './styles';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { Icon } from '../../icon';
import { track, TrackingType } from '../../../tracking/ga';

type Props = {
  downloadUrl: string | null;
  downloadExpired?: boolean;
  downloadAvailableDays?: number;
  userEmail: string;
};

export const DocumentDone: React.FC<Props> = (props) => {
  return (
    <>
      <Headline>Payment confirmed</Headline>
      <Check>
        <Icon icon={faCheck} size="3x" />
      </Check>
      {props.downloadExpired ? (
        <Text>The link is expired</Text>
      ) : (
        <Text data-testid="auto_download_text">
          Your download should start automatically. <br />{' '}
          {props.downloadUrl && (
            <>
              If not, please{' '}
              <a
                href={props.downloadUrl}
                download
                onClick={() =>
                  track(
                    'event',
                    TrackingType.Category.paymentPage,
                    TrackingType.Actions.manuallyFileDownload,
                    TrackingType.Labels.downloadLink,
                  )
                }
              >
                click here to download.
              </a>
            </>
          )}
        </Text>
      )}
      <Text css={{ fontWeight: 500 }} color="main">
        Your file is available for {props.downloadAvailableDays}{' '}
        {props.downloadAvailableDays && props.downloadAvailableDays > 1
          ? 'days'
          : 'day'}
        .
      </Text>
      <Text>
        We have sent your receipt and a link to download your document to{' '}
        {props.userEmail}
      </Text>
    </>
  );
};
