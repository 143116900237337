import { useDocument } from '../context/document-context';
import { useRouter } from 'next/router';

type Props = {
  withConfirm?: boolean;
  confirmMessage?: string;
};

export const useResetEditor = ({ withConfirm, confirmMessage }: Props) => {
  const { push, query } = useRouter();
  const { send: sendDocumentMachine } = useDocument();
  const reset = () => {
    const userConfirmed = withConfirm
      ? window.confirm(
          confirmMessage ||
            "Are you sure you want clear? This action can't be undone",
        )
      : true;
    if (userConfirmed) {
      sendDocumentMachine({ type: 'UPLOAD_CLEAR' });
      push({
        pathname: '/',
        query: query.testPaymentSecret
          ? { testPaymentSecret: query.testPaymentSecret }
          : {},
      });
    }
  };

  return { reset };
};
