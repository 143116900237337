import { BuyArea } from '@components/buy-area';
import { Preview } from '@components/preview';
import { SuggestionsBrowser } from '@components/suggestions-browser';
import { WordLimitFooter } from '@components/word-limit-footer';
import { useEffect } from 'react';
import { usePreviewMachine } from '@machines/preview.machine';
import { State } from 'xstate';
import { TContext, TEvent, TState } from '@machines/document.machine';
import { styled } from '../../stitches.config';

type Props = {
  documentState: State<TContext, TEvent, any, TState>;
};

export const EditorWorkspace: React.FC<Props> = ({ documentState }) => {
  const [previewState, send] = usePreviewMachine();
  useEffect(() => {
    if (documentState.context.preview && documentState.context.sessionId) {
      send({
        type: 'INITIALIZE_PREVIEW_TEXT',
        text: documentState.context.preview,
        sessionId: documentState.context.sessionId,
      });
    }
  }, [send, documentState.context.preview, documentState.context.sessionId]);
  return (
    <>
      <AsideLeft />
      <Editor>
        <Preview
          onSuggestionClick={(suggestionId: string) => () => {
            send({ type: 'SELECT_SUGGESTION', suggestionId });
          }}
          selectedSuggestionId={previewState.context.selectedSuggestionId}
          sentences={previewState.context.sentences}
        />
        <WordLimitFooter />
      </Editor>

      <AsideRight>
        <AsideRightInner>
          <SuggestionsBrowser />
          <BuyArea />
        </AsideRightInner>
      </AsideRight>
    </>
  );
};

const Editor = styled('div', {
  flex: '4 1 0%',
  maxWidth: '720px',
  fontFamily: 'IBM Plex Serif, Segoe UI, Tahoma, sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
  color: 'rgb(10, 35, 92)',
  paddingTop: '20px',
  cursor: 'text',
  alignSelf: 'flex-start',
});

const AsideLeft = styled('aside', {
  flex: '1 1 0%',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '304px',
});

const AsideRight = styled('aside', {
  height: '100%',
  flex: '1 1 0%',
  minWidth: '304px',
  display: 'flex',
});

const AsideRightInner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: '5rem',
  marginRight: '1rem',
  width: '252px',
});
