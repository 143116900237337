import { useEffect, useState } from 'react';

import { useModal } from '@context/modal-context';
import { isDevelopment } from '@utils/helpers';
import { PaymentModal } from './modals/payment-modal/payment-modal';
import { TocModal } from './modals/toc-modal/toc-modal';
import { styled } from '../../stitches.config';

const Panel = styled('div', {
  position: 'absolute',
  bottom: '5px',
  left: '5px',
  borderRadius: '16px',
  backgroundColor: '#fff',
  padding: '1rem',
  border: '1px solid #333',
  width: '180px',
});

const PanelHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '1.2rem',
});

const PanelBody = styled('div', {
  marginTop: '1rem',
});

export const DevelopmentPanel: React.FC = () => {
  const [show, setShow] = useState<'open' | 'close'>('close');

  const handelDevelopmentPanel = () => {
    setShow((prev) => (prev === 'open' ? 'close' : 'open'));
  };

  useEffect(() => {
    localStorage.setItem('devPanel', show);
  }, [show]);

  const { open } = useModal();

  return isDevelopment && process.env.NEXT_PUBLIC_DEV_PANEL ? (
    <Panel>
      <PanelHeader>
        <b>Dev Panel</b>
        <button onClick={handelDevelopmentPanel}>
          {show === 'open' ? 'close' : 'open'}
        </button>
      </PanelHeader>
      {show === 'open' && (
        <PanelBody>
          <button onClick={() => open({ children: <PaymentModal /> })}>
            open Payment modal
          </button>
          <button onClick={() => open({ children: <TocModal /> })}>
            open TOC modal
          </button>
        </PanelBody>
      )}
    </Panel>
  ) : null;
};
