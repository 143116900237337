import { styled } from '../../stitches.config';

export const Pipe = () => {
  return <StyledPipe />;
};

const StyledPipe = styled('span', {
  width: '1px',
  backgroundColor: '#0a235c',
  height: '100%',
  margin: '0 1rem',
  opacity: '0.7',
});
