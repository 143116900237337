import { useState } from 'react';
import { styled } from '../../../../stitches.config';

import { ModalTitle } from '../modal-title';
import { Cookies } from './cookies';
import { Privacy } from './privacy';
import { Terms } from './terms';

type TabState = 'terms' | 'privacy' | 'cookies';

export const TocModal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabState>('terms');

  return (
    <Wrapper>
      <ModalTitle data-testid="toc_modal_title">
        Paperpal Terms and Policies
      </ModalTitle>
      <Tabs data-testid="toc_modal_tabs">
        <Tab tab="terms" {...{ activeTab, setActiveTab }}>
          Terms of Use
        </Tab>
        <Tab tab="privacy" {...{ activeTab, setActiveTab }}>
          Privacy Policy
        </Tab>
        <Tab tab="cookies" {...{ activeTab, setActiveTab }}>
          Cookies Policy
        </Tab>
      </Tabs>
      <Content key={activeTab} data-testid="toc_modal_body">
        {activeTab === 'terms' && <Terms />}
        {activeTab === 'privacy' && <Privacy />}
        {activeTab === 'cookies' && <Cookies />}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  maxHeight: 'calc(100vh - 10rem)',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const Tabs = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const Content = styled('div', {
  padding: '4rem',
  flex: 'auto',
  overflowY: 'auto',
  '& *:not(:last-child)': {
    marginBottom: '2rem',
  },
  h2: {
    fontSize: '2.8rem',
    lineHeight: '3rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: '2rem',
  },
  ul: {
    paddingLeft: '2rem',
  },
});

const Tab: React.FC<{
  tab: TabState;
  activeTab: TabState;
  setActiveTab: (tab: TabState) => void;
}> = ({ activeTab, tab, children, setActiveTab }) => (
  <TabButton isActive={activeTab === tab} onClick={() => setActiveTab(tab)}>
    {children}
  </TabButton>
);

const TabButton = styled('button', {
  variants: {
    isActive: {
      true: {
        color: '#0a235c',
        borderBottomColor: '$mainPrimary',
      },
    },
  },
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  background: 'none',
  padding: '0 4rem 1.6rem',
  fontSize: '1rem',
  color: '#7985a3',
  borderBottom: '2px solid #f7f9fc',
});
