import { styled } from '../../../stitches.config';

export const CoreHelperText: React.FC = ({ children, ...props }) => {
  return (
    <StyledHelperText data-testid="input_helper_text" {...props}>
      {children || <>&nbsp;</>}
    </StyledHelperText>
  );
};

const StyledHelperText = styled('p', {
  marginBottom: '0 !important',
  fontWeight: 400,
});
