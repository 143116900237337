import { styled } from '../../../../stitches.config';

export const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'wrap',
  minHeight: '684px',
});

export const Content = styled('div', {
  variants: {
    bg: {
      primary: {
        backgroundColor: '$mainBg',
      },
      secondary: {
        backgroundColor: '$secondaryBg',
      },
    },
  },
  display: 'grid',
  flex: '1',
  rowGap: '2rem',
  placeItems: 'center',
  padding: '2rem',
  borderRadius: '1rem',
  backgroundColor: 'none',
  width: '444px',
});

export const MSWord = styled('div', {
  display: 'grid',
  placeItems: 'center',
  background: '$secondaryBg',
  borderRadius: '50%',
  width: '11.25rem',
  height: '11.25rem',
});

export const Check = styled(MSWord, {
  variants: {
    bg: {
      error: {
        color: '$mainError',
        background: '$secondaryError',
      },
    },
  },
  size: '8rem',
  color: '$mainPrimary',
  background: '$secondaryPrimary',
});

export const Headline = styled('h2', {
  fontSize: '$regular',
  fontWeight: 500,
});

export const Text = styled('p', {
  variants: {
    color: {
      main: {
        color: '$mainText',
      },
    },
  },
  fontSize: '$small',
  textAlign: 'center',
  lineHeight: '1.5rem',
  fontWeight: 400,
  color: '$secondaryText',
});
