import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
//should be arrow-up-from-bracket but we don't have version 6 yet
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';

import { useDocument } from '../context/document-context';
import { track, TrackingType } from '../tracking/ga';
import { Button } from './button';
import { Icon } from './icon';

export const UploadButton: React.FC = () => {
  const { dropZone, send, isUploading } = useDocument();

  return isUploading ? (
    <Button variant="link" onClick={() => send({ type: 'UPLOAD_CANCEL' })}>
      <Icon icon={faTimes} spacer={32} />
      Cancel
    </Button>
  ) : (
    <div {...dropZone.getRootProps({ 'data-testid': 'upload_right_top' })}>
      <input {...dropZone.getInputProps()} />
      <Button
        variant="link"
        onClick={() =>
          track(
            'event',
            TrackingType.Category.uploadPage,
            TrackingType.Actions.uploadIntent,
            TrackingType.Labels.uploadTop,
          )
        }
      >
        <Icon icon={faUpload} spacer={32} />
        Upload Word doc
      </Button>
    </div>
  );
};
