import { forwardRef } from 'react';
import { styled } from '../../../stitches.config';

import { CoreHelperText } from './core-helper-text';
import { CoreLabel } from './core-label';
import type { FieldBase } from './types';

type Props = FieldBase & {
  id?: string;
};

export const Checkbox = forwardRef<any, Props>(
  ({ label, id, className, errors, ...props }, reference) => {
    return (
      <Label className={className}>
        <StyledCheckbox
          id={id || props.name}
          {...props}
          type="checkbox"
          ref={reference}
        />
        {label}
        <HelperText>{errors[props.name]?.message}</HelperText>
      </Label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

const Label = styled(CoreLabel, {
  fontSize: '1rem',
  fontWeight: 400,
  cursor: 'pointer',
});

const HelperText = styled(CoreHelperText, {
  color: '$mainError',
  fontSize: '0.75rem',
  marginLeft: '0.5rem',
});

const StyledCheckbox = styled('input', {
  marginRight: '0.5rem',
});
