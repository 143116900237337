import { DevelopmentPanel } from '@components/_development-panel.tsx';
import { ClearButton } from '@components/clear-button';
import { Environment } from '@components/environment';
import { Logo } from '@components/logo';
import { UploadButton } from '@components/upload-button';
import { useResetEditor } from '@hooks/use-reset-editor';
import { Footer } from '../layout/footer';
import { Layout } from '../layout/layout';
import { UploadPage } from 'features/upload-page';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorDisplay } from '@components/error-display';
import { styled } from '../../stitches.config';

const App: React.FC = () => {
  const { reset } = useResetEditor({ withConfirm: false });

  return (
    <Layout
      header={
        <>
          <LogoWrapper data-testid="logo" onClick={reset}>
            <Logo />
          </LogoWrapper>
          <Actions>
            <ClearButton />
            <UploadButton />
          </Actions>
        </>
      }
      footer={<Footer />}
    >
      {/*@ts-expect-error*/}
      <ErrorBoundary onReset={reset} FallbackComponent={ErrorDisplay}>
        <UploadPage />
      </ErrorBoundary>

      <Environment />
      <DevelopmentPanel />
    </Layout>
  );
};

export default App;

const Actions = styled('div', {
  display: 'flex',
  '& > :not(:last-child)': { marginRight: '1rem' },
});

const LogoWrapper = styled('div', { cursor: 'pointer' });
