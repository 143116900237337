import { styled } from '../../stitches.config';

const getHeight = ({ axis, size }: Props) => {
  return axis === 'horizontal' ? 1 : size;
};
const getWidth = ({ axis, size }: Props) => {
  return axis === 'vertical' ? 1 : size;
};

type Props = {
  size: number;
  axis?: 'horizontal' | 'vertical';
  inline?: boolean;
};
/**
 * Spacer component to add spacing between any two elements.
 * From https://www.joshwcomeau.com/react/modern-spacer-gif/#the-code
 */

export const Spacer: React.FC<Props> = (props) => {
  const width = getWidth(props);
  const height = getHeight(props);
  return (
    <StyledSpacer
      css={{
        width,
        minWidth: width,
        height,
        minHeight: height,
        display: props.inline ? 'inline-block' : 'block',
      }}
    />
  );
};

const StyledSpacer = styled('span', {});
