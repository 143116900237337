import { forwardRef } from 'react';
import { styled } from '../../../stitches.config';

import { CoreHelperText } from './core-helper-text';
import { CoreLabel } from './core-label';
import type { FieldBase } from './types';

type Props = FieldBase & {
  options: Array<{ value: string | number; label: string }>;
  label: string;
};
export const Select = forwardRef<any, Props>(
  ({ options, label, id, errors, className, ...props }, reference) => {
    const error = errors[props.name];
    return (
      <Label className={className}>
        <span>{label}</span>
        <StyledSelect
          id={id || props.name}
          {...props}
          ref={reference}
          defaultValue=""
          error={Boolean(error?.message)}
        >
          <option hidden disabled value="" />
          {options.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </StyledSelect>
        <HelperText>{error?.message}</HelperText>
      </Label>
    );
  },
);

Select.displayName = 'Select';

const Label = styled(CoreLabel, {
  color: '#0a235c',
  '& > span': {
    display: 'block',
    marginLeft: '0.5rem',
    marginBottom: '0.5rem',
    textAlign: 'left',
  },
});

const HelperText = styled(CoreHelperText, {
  color: '$mainError',
  textAlign: 'left',
  marginLeft: '0.5rem',
});

const StyledSelect = styled('select', {
  variants: {
    error: {
      true: {
        borderColor: '$mainError',
      },
    },
  },
  minHeight: '50px',
  fontSize: '1rem',
  borderRadius: '0.5rem',
  backgroundColor: '#ffffff',
  boxShadow:
    '0 0 1px 0 rgba(10, 35, 92, 0.5), 0 4px 16px -8px rgba(10, 35, 92, 0.25)',
  width: '100%',
  lineHeight: '1.5rem',
  outline: 'none',
  appearance: 'none',
  cursor: 'pointer',
  border: '1px solid transparent',
  padding: '0.75rem',
  display: 'grid',
  '&:focus': {
    borderColor: '#0060ff',
  },
});
