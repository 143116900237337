import { styled } from '../../stitches.config';

type Props = {
  header: React.ReactElement;
  footer: React.ReactElement;
};

export const Layout: React.FC<Props> = ({ header, children, footer }) => {
  return (
    <Container>
      <Header>{header}</Header>

      <Main>
        <Content>{children}</Content>
      </Main>
      {footer}
    </Container>
  );
};

export const Container = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
});

export const Header = styled('header', {
  width: '100%',
  background: 'white none repeat scroll 0 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 3rem',
  boxShadow: 'rgba(0, 31, 102, 0.04) 0 4px 0',
});

export const Main = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
});

export const Content = styled('div', {
  display: 'flex',
  flex: '1 1 0%',
  paddingTop: '36px',
});
