import { styled } from '../../../stitches.config';

type Props = {
  htmlFor?: string;
  className?: string;
};

export const CoreLabel: React.FC<Props> = (props) => {
  return <StyledLabel {...props} />;
};

const StyledLabel = styled('label', {
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '1rem',
});
