import { useEffect, useRef } from 'react';
import { styled } from '../../stitches.config';

export const Environment: React.FC = () => {
  const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  const localPrefix = useRef('');

  useEffect(() => {
    const hostname =
      typeof window !== 'undefined' && window.location.hostname
        ? window.location.hostname
        : '';
    localPrefix.current = hostname.includes('localhost') ? 'local ↔️ ' : '';
  }, []);

  if (apiBaseUrl?.includes('localhost')) {
    return <EnvironmentLabel>local</EnvironmentLabel>;
  } else if (apiBaseUrl?.includes('dev')) {
    return <EnvironmentLabel>{localPrefix.current}dev</EnvironmentLabel>;
  } else if (apiBaseUrl?.includes('stage')) {
    return <EnvironmentLabel>{localPrefix.current}stage</EnvironmentLabel>;
  } else if (localPrefix.current) {
    return <EnvironmentLabel>{localPrefix.current}prod</EnvironmentLabel>;
  } else {
    return null;
  }
};

const EnvironmentLabel = styled('div', {
  display: 'block',
  borderRadius: '0.3rem',
  color: '#fff',
  fontSize: '$regular',
  fontWeight: 500,
  padding: '0.3rem 1rem',
  background: 'rgb(51, 156, 143) none repeat scroll 0% 0%',
  width: '220px',
  textAlign: 'center',
  position: 'absolute',
  transform: 'rotate(-45deg)',
  left: '-70px',
  top: '27px',
});
