import { GetFileButton } from './get-file-button';
import { PRICE } from '@utils/constants';
import { styled } from '../../stitches.config';

export const BuyArea: React.FC = () => {
  return (
    <Wrapper data-testid="buy_area">
      <Text>
        Your Paperpal Edited Word file includes all our edits as tracked changes
        in your manuscript.
      </Text>
      <GetFileButton fullWidth={true} gaPlace="sidebar" price={PRICE}>
        Download your file
      </GetFileButton>
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  position: 'sticky',
  bottom: '32px',
  fontSize: '$small',
});

const Text = styled('div', {
  margin: '0.5rem 0 1rem 0',
});
