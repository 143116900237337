type Props = React.SVGAttributes<SVGElement> & {
  look?: 'default' | 'gray';
};

export const MSWordIcon = (props: Props) =>
  props.look === 'gray' ? (
    <MSWordLightIcon {...props} />
  ) : (
    <MSWordDefaultIcon {...props} />
  );

const MSWordDefaultIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 96 82'} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#979593"
        d="M16.5,66.0756836 C16.5,68.8997589 16.5,73.374531 16.5,79.5 C16.5,80.6046 17.3954,81.5 18.5,81.5 L76.5,81.5 C77.6046,81.5 78.5,80.6046 78.5,79.5 L78.5,20.3284 C78.5,19.798 78.2893,19.2893 77.9142,18.9142 L61.0858,1.0858 C60.7107,0.7107 60.202,0.5 59.6716,0.5 L18.5,0.5 C17.3954,0.5 16.5,1.3954 16.5,2.5 C16.5,13.8945312 16.5,21.4404297 16.5,27.1376953"
      />
      <path
        stroke="#979593"
        d="M59.5,0.5 L59.5,18.5 C59.5,19.6046 60.3954,20.5 61.5,20.5 L78.5,20.5"
      />
      <path
        fill="#C8C6C4"
        fillRule="nonzero"
        d="M71,54 L39.5,54 L39.5,52 L71,52 C71.5523,52 72,52.4477 72,53 C72,53.5523 71.5523,54 71,54 Z M71,48 L39.5,48 L39.5,46 L71,46 C71.5523,46 72,46.4477 72,47 C72,47.5523 71.5523,48 71,48 Z M71,42 L39.5,42 L39.5,40 L71,40 C71.5523,40 72,40.4477 72,41 C72,41.5523 71.5523,42 71,42 Z M71,36 L39.5,36 L39.5,34 L71,34 C71.5523,34 72,34.4477 72,35 C72,35.5523 71.5523,36 71,36 Z M71,60 L39.5,60 L39.5,58 L71,58 C71.5523,58 72,58.4477 72,59 C72,59.5523 71.5523,60 71,60 Z"
      />
      <path
        fill="#185ABD"
        fillRule="nonzero"
        d="M4,67 L36,67 C38.2091,67 40,65.2091 40,63 L40,31 C40,28.7909 38.2091,27 36,27 L4,27 C1.7909,27 0,28.7909 0,31 L0,63 C0,65.2091 1.7909,67 4,67 Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M13.6245,53.6455 C13.6906,54.1675 13.7335,54.6224 13.7541,55.0112 L13.8303,55.0112 C13.8609,54.6427 13.9192,54.1983 14.0054,53.6763 C14.0916,53.1552 14.1757,52.7153 14.2571,52.3573 L17.7911,37 L22.3613,37 L26.0175,52.1272 C26.2005,52.874 26.3528,53.8245 26.4745,54.9804 L26.5353,54.9804 C26.5861,54.1825 26.713,53.262 26.9162,52.2189 L29.8413,37 L34,37 L28.8817,59 L24.0217,59 L20.5332,44.4256 C20.4316,44.0059 20.3174,43.4593 20.1904,42.7839 C20.0634,42.1094 19.9847,41.6183 19.9544,41.3115 L19.8936,41.3115 C19.8529,41.6695 19.7741,42.2011 19.6572,42.9065 C19.5403,43.6127 19.4462,44.1338 19.3753,44.4715 L16.1,59 L11.1643,59 L6,37 L10.2349,37 L13.4192,52.3882 C13.4901,52.7047 13.5584,53.1244 13.6245,53.6455 Z"
      />
    </g>
  </svg>
);

const MSWordLightIcon = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 96 82'} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke={props.color || '#C2C8D7'}
        d="M16.5,66.0756836 C16.5,68.8997589 16.5,73.374531 16.5,79.5 C16.5,80.6046 17.3954,81.5 18.5,81.5 L76.5,81.5 C77.6046,81.5 78.5,80.6046 78.5,79.5 L78.5,20.3284 C78.5,19.798 78.2893,19.2893 77.9142,18.9142 L61.0858,1.0858 C60.7107,0.7107 60.202,0.5 59.6716,0.5 L18.5,0.5 C17.3954,0.5 16.5,1.3954 16.5,2.5 C16.5,13.8945312 16.5,21.4404297 16.5,27.1376953"
      />
      <path
        stroke={props.color || '#C2C8D7'}
        d="M59.5,0.5 L59.5,18.5 C59.5,19.6046 60.3954,20.5 61.5,20.5 L78.5,20.5"
      />
      <path
        fill={props.color || '#C2C8D7'}
        fillRule="nonzero"
        d="M71,54 L39.5,54 L39.5,52 L71,52 C71.5523,52 72,52.4477 72,53 C72,53.5523 71.5523,54 71,54 Z M71,48 L39.5,48 L39.5,46 L71,46 C71.5523,46 72,46.4477 72,47 C72,47.5523 71.5523,48 71,48 Z M71,42 L39.5,42 L39.5,40 L71,40 C71.5523,40 72,40.4477 72,41 C72,41.5523 71.5523,42 71,42 Z M71,36 L39.5,36 L39.5,34 L71,34 C71.5523,34 72,34.4477 72,35 C72,35.5523 71.5523,36 71,36 Z M71,60 L39.5,60 L39.5,58 L71,58 C71.5523,58 72,58.4477 72,59 C72,59.5523 71.5523,60 71,60 Z"
      />
      <path
        stroke={props.color || '#C2C8D7'}
        d="M36,27.5 L4,27.5 C2.1314743,27.5 0.604876064,28.964314 0.505178988,30.8079684 L0.5,31 L0.5,63 C0.5,64.8685257 1.96431404,66.3951239 3.80796844,66.494821 L4,66.5 L36,66.5 C37.8685257,66.5 39.3951239,65.035686 39.494821,63.1920316 L39.5,63 L39.5,31 C39.5,29.1314743 38.035686,27.6048761 36.1920316,27.505179 L36,27.5 Z"
      />
      <path
        fill={props.color || '#C2C8D7'}
        fillRule="nonzero"
        d="M13.6245,53.6455 C13.6906,54.1675 13.7335,54.6224 13.7541,55.0112 L13.8303,55.0112 C13.8609,54.6427 13.9192,54.1983 14.0054,53.6763 C14.0916,53.1552 14.1757,52.7153 14.2571,52.3573 L17.7911,37 L22.3613,37 L26.0175,52.1272 C26.2005,52.874 26.3528,53.8245 26.4745,54.9804 L26.5353,54.9804 C26.5861,54.1825 26.713,53.262 26.9162,52.2189 L29.8413,37 L34,37 L28.8817,59 L24.0217,59 L20.5332,44.4256 C20.4316,44.0059 20.3174,43.4593 20.1904,42.7839 C20.0634,42.1094 19.9847,41.6183 19.9544,41.3115 L19.8936,41.3115 C19.8529,41.6695 19.7741,42.2011 19.6572,42.9065 C19.5403,43.6127 19.4462,44.1338 19.3753,44.4715 L16.1,59 L11.1643,59 L6,37 L10.2349,37 L13.4192,52.3882 C13.4901,52.7047 13.5584,53.1244 13.6245,53.6455 Z"
      />
    </g>
  </svg>
);
