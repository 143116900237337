import { cloneElement, forwardRef, ReactElement } from 'react';
import { styled } from '../../../stitches.config';

import { CoreHelperText } from './core-helper-text';
import { CoreLabel } from './core-label';
import type { FieldBase } from './types';

type Props = FieldBase & {
  type?: 'text' | 'number';
  className?: string;
  Component?: ReactElement;
  options?: any;
};

type ClassNameConsumerProps = {
  className?: string;
  children: (className?: string) => React.ReactElement;
};

export const Input = forwardRef<any, Props>(
  (
    { Component, label, id, errors, fullWidth, className, ...props },
    reference,
  ) => {
    const error = errors[props.name];
    return (
      <Label className={className}>
        <span>{label}</span>
        <StyledInput css={error?.message && { borderColor: '$mainError' }}>
          {(className) =>
            Component ? (
              cloneElement(Component, { className, ref: reference })
            ) : (
              <input
                className={className}
                id={id || props.name}
                type="text"
                {...props}
                ref={reference}
              />
            )
          }
        </StyledInput>
        <HelperText>{error?.message}</HelperText>
      </Label>
    );
  },
);

Input.displayName = 'Input';

const Label = styled(CoreLabel, {
  color: '#0a235c',
  '& > span': {
    display: 'block',
    marginLeft: '0.5rem',
    marginBottom: '0.5rem',
    textAlign: 'left',
  },
});

const HelperText = styled(CoreHelperText, {
  color: '$mainError',
  textAlign: 'left',
  marginLeft: '0.5rem',
});

const StyledInput = styled(
  ({ className, children }: ClassNameConsumerProps) => {
    return children(className);
  },
  {
    minHeight: '50px',
    fontSize: '1rem',
    borderRadius: '0.5rem',
    backgroundColor: '#ffffff',
    boxShadow:
      '0px 0px 1px rgba(10, 35, 92, 0.5), 0px 4px 16px -8px rgba(10, 35, 92, 0.25)',
    width: '100%',
    lineHeight: '1.5rem',
    outline: 'none',
    border: '1px solid transparent',
    padding: '0.75rem',
    '&:focus, &.StripeElement--focus': {
      borderColor: '#0060ff',
    },
  },
);
