export const Privacy = () => (
  <>
    <h2>Privacy Policy</h2>
    <p>
      Your privacy is important to us, and the Cactus Communications Group
      (&quot;CACTUS,&quot; &quot;the Company,&quot; &quot;we,&quot;
      &quot;our,&quot; or &quot;us&quot;) is committed to protecting your
      privacy whilst providing a personalized and valuable service.
    </p>
    <p>
      Here at CACTUS, we are committed to safeguarding your data while rendering
      our professional services. Paperpal comprises a portfolio of services and
      products offered by CACTUS that operates through various group entities
      including, but not limited to, entities located in Singapore, India,
      Japan, China, Korea, Denmark, the United Kingdom, and the United States.
    </p>
    <p>
      This Privacy Policy governs our data collection, processing, and usage
      practices. It is designed to allow the Data Subject (&quot;you&quot;) to
      make an informed choice regarding use, access, and correction of your
      Personal Information.
    </p>
    <p>
      If you have any requests concerning your Personal Information or any
      queries about these practices, please contact us by e-mail on
      privacy@cactusglobal.com or you can contact us by writing to Cactus
      Communications Private Limited, A 603, Satellite Gazebo, Guru Hargovindji
      Marg, Andheri (East), Mumbai 400093, Maharashtra, India.
    </p>
    <h3>Personal Data That We Collect</h3>
    <p>
      We may collect from you Personal Information such as full name, gender,
      date of birth, address, job title, company information, e-mail addresses,
      telephone numbers, identification details, and referral source, and if you
      are a Service Provider, we will also collect payment information
      (including bank, credit/debit card details, and electronic payment ids)
      billing address, and work and education profile. This data may include IP
      Address, operating system, and browser type. We also collect data relating
      to your or your institutions&rsquo; published research papers, documents,
      articles etc. and your personal preferences with regard to the journals
      you like to read, your most preferred published papers, etc. We do not
      intentionally collect more personal data than described here. However if
      you at your choice, intentionally or unintentionally disclose some
      personal data other than the description provided in this paragraph then
      such data shall continue to be governed by this Privacy Policy. We may
      also have acquired your Personal Information from a third-party Service
      Provider or publicly available data source.
    </p>
    <h4>1. How We Use Your Personal Data</h4>
    <h5>As a Data Controller:</h5>
    <p>
      We use the information we collect only in compliance with this Privacy
      Policy. We will never sell your Personal Information to any third party
      without your explicit consent. In addition to the uses identified
      elsewhere in this Privacy Policy, we may use your Personal Information to
      (a) assist you with service, partnership, or employment request; (b) send
      to you information that we think may be of interest to you by post, email,
      or other means; (c) send you marketing communications relating to our
      business (including any profiling we engage in that is related to such
      direct marketing); (d) respond to an enquiry, question, or concern that
      you may have raised; (e) perform analytics and create a database to
      improve your browsing experience and enhance the functionality of our
      services; or (f) send you on email or by any other means a suggested
      reading list based on your personal preferences submitted to us.
    </p>
    <p>
      We may share Personal Information with trusted Service Providers or our
      group companies for payment processing, supplementing the information you
      provide us in order to provide you with better service, perform
      statistical analysis, provide marketing assistance, host the site and
      index user profile pages and usernames as part of the URL and customer
      support. Any third parties receiving your Personal Information from us are
      prohibited from using your Personal Information except for these purposes,
      and they are required to maintain the confidentiality of your information.
      If you do not want us to share your Personal Information with these third
      parties, contact us at privacy@cactusglobal.com.
    </p>
    <p>
      To facilitate our global operations, we may transfer and access Personal
      Information from around the world. This Privacy Policy shall apply even if
      we transfer Personal Information to countries outside the European Union.
    </p>
    <p>
      After you place an order on our Website, you may need to make payments for
      the services you have ordered. In order to process your payments, we use a
      number of third-party payment processors. Your payment will be processed
      by the payment provider you choose to process your payment via a payment
      gateway.
    </p>
    <p>
      If you give us banking, debit card, credit card, or other payment mode
      details, we solely use such details to process payment refunds, if any, to
      you. The third-party payment processors we use also collect, use, and
      process your information, including payment information, in accordance
      with their respective privacy policies.
    </p>
    <p>
      To ensure that the security and integrity of the Site is safeguarded,
      CACTUS may monitor your Personal Information. CACTUS may under certain
      circumstances engage a trusted third-party Service Provider for the
      purpose of managing the technical operations involved in hosting the Site.
      Information you submit to us via the contact form or any other forms
      required for availing any of our services on our Website will be stored
      outside the European Economic Area on our third-party hosting
      provider&rsquo;s servers in United States of America. To the extent that
      it may be necessary, and solely for the purposes of providing the service
      available on the Site to you, we may disclose to such third party any of
      your Personal Information that may be necessary for the procurement of
      services from the third party.
    </p>
    <h5>As a Data Processor:</h5>
    <p>
      We shall use and process the information or data we collect through Data
      Controllers/Controllers (&quot;Business Partners&quot;) as per the
      instructions given by Controllers and in compliance with the terms and
      conditions applicable to Processors under the Controller/Processor Binding
      Agreement entered with the Controllers and applicable Regulations. We
      agree not to process such information to sub-processors (Sub-contractor),
      unless prior written consent is sought from the Controller, and in the
      event where general consent has been given by the Controller, we shall
      inform the Controller of any new sub-processor. We shall have the same
      contractual obligations reflected with the sub-processors as agreed with
      the Controller and assume complete responsibility for the actions and
      inactions of such sub-processors.
    </p>
    <p>
      Upon written request from the Controller, we agree to delete or return all
      the Personal Information or data to the Controller after the end of the
      provision of services relating to processing, unless there is a legal
      obligation or basis for us to retain a copy of such information or data
      for further processing. The information or data collected through the
      Controller will be stored outside the European Economic Area on our
      third-party hosting provider&rsquo;s servers.
    </p>
    <p>
      In addition to implementation of appropriate technical and organizational
      measures to ensure an appropriate level of security, we shall make
      available to the Controller all information necessary to demonstrate
      compliance with our obligations, and allow the Controller or another
      auditor appointed by the Controller for conducting audits, including
      inspections, to demonstrate that processing meets the requirements of the
      Regulation, and ensure the protection of the rights of the data subject.
    </p>
    <h4>2. Children&rsquo;s Personal Information</h4>
    <p>
      CACTUS does not knowingly collect any Personal Information from children
      under the age of 18. If you are under the age of 18, please do not submit
      any Personal Information through our Websites or Service(s). We encourage
      parents and legal guardians to monitor their children&rsquo;s Internet
      usage and to help enforce this Policy by instructing their children never
      to provide Personal Information through our Service(s) or Websites without
      their permission. If you have reason to believe that a child under the age
      of 18 has provided Personal Information to us through our Websites or
      Service(s), please contact us at privacy@cactusglobal.com, and we will
      endeavor to delete that information and terminate the child&rsquo;s
      account from our databases.
    </p>
    <h4>3. Retention of Personal Information</h4>
    <p>
      We retain Personal Information that you provide us as long as an Account
      is active or as needed to provide the Service(s) or as necessary to comply
      with our legal obligations, maintain accurate financial and other records,
      resolve disputes, and enforce our agreements. If you wish to terminate
      your Account or request that we no longer use Your Personal Information,
      please contact us at privacy@cactusglobal.com.
    </p>
    <h5>Criteria for determining retention periods</h5>
    <p>
      In any other circumstances, we will retain your information for no longer
      than necessary, taking into account the following:
    </p>
    <ul>
      <li>
        the purpose(s) and use of your information both now and in the future
        (such as whether it is necessary to continue to store that information
        in order to continue to perform our obligations under a contract with
        you or to contact you in the future);
      </li>
      <li>
        whether we have any legal obligation to continue to process your
        information (such as any record-keeping obligations imposed by relevant
        law or regulation);
      </li>
      <li>
        whether we have any legal basis to continue to process your information
        (such as your consent);
      </li>
      <li>how valuable your information is (both now and in the future);</li>
      <li>
        any relevant agreed industry practices on how long information should be
        retained;
      </li>
      <li>
        the levels of risk, cost, and liability involved with us continuing to
        hold the information;
      </li>
      <li>
        how hard it is to ensure that the information can be kept up to date and
        accurate;
      </li>
      <li>
        any relevant surrounding circumstances (such as the nature and status of
        our relationship with you);and
      </li>
      <li>the time period your account remains active.</li>
    </ul>
    <h4>4. Use of Cookies &amp; Similar Technologies</h4>
    <p>
      Cookies are data files that are sent from a website to a browser to record
      information about users for various purposes.
    </p>
    <p>
      We use cookies, including essential, functional, and analytical cookies.
      For further information on how we use cookies, please see our cookies
      policy.
    </p>
    <p>
      You can reject some or all of the cookies we use on or via our Website by
      changing your browser settings, but doing so can impair your ability to
      use our Website or some or all of its features. For further information
      about cookies, including how to change your browser settings and other
      ways in which you can reject cookies, please visit www.allaboutcookies.org
      or see our cookies policy.
    </p>
    <h4>5. Social Media Features</h4>
    <p>
      Some of our Websites including, researcher.life, _______ include
      integrations with social media sites, such as Facebook, LinkedIn, Twitter,
      and ORCID, the &quot;Share This&quot; button, or interactive mini-
      programs. Where we have your consent, these features may collect your IP
      address and which page you are visiting on our Websites, and may set a
      cookie to enable the feature to function properly. Social media features
      and widgets are either hosted by a third party or hosted directly on our
      Websites. Your interactions with these features are governed by the
      privacy policy of the company providing them.
    </p>
    <h4>6. Security of Your Personal Information</h4>
    <p>
      CACTUS has put in place the following security practices and procedures to
      protect your Personal Information from unauthorized access, use, or
      disclosure:
    </p>
    <ol>
      <li>
        We secure the Personal Information you provide on computer servers in a
        controlled, secure environment, protected from unauthorized access, use,
        or disclosure
      </li>
      <li>
        All our Information security processes are ISO/IEC 27001:2013 compliant.
      </li>
      <li>
        All our employees and Service Providers have signed confidentiality
        agreements where they have undertaken that they will not make any
        Personal Information available to anyone, unless authorized in writing
        by the user.
      </li>
      <li>
        Personal Information will not be made available to any third party,
        except as provided herein or by obtaining your prior written consent.
      </li>
      <li>
        Wherever applicable all documents submitted are managed through a secure
        online job management system; the documents that are sent to us can only
        be viewed by service professionals working on your assignment.
      </li>
      <li>Payment-related Personal Information:</li>
      <ol>
        <li>
          The Site uses Secure Socket Layer (SSL) encryption when accepting
          payment- related Personal Information, to ensure that your
          payment-related Personal Information is secure.
        </li>
        <li>
          In order to protect your credit card information, all online
          transactions are conducted using a secure 256-bit SSL encryption
          signed by VeriSign. Encryption ensures that no information is
          accessible to unknown parties. CACTUS hereby undertakes not to store
          your credit card information, thereby reducing the possibility of
          fraudulent charges being made by CACTUS.
        </li>
      </ol>
    </ol>
    <h4>7. Hyperlinks</h4>
    <p>
      Our Company Websites contain links to other websites, including without
      limitation those websites of other companies within the CACTUS group.
      Their inclusion cannot be taken to imply any endorsement or validation by
      us of the content of the said websites. We are not responsible for the
      privacy practices nor do we accept any liability about the content of such
      websites including those of our group companies that have their own
      privacy policies tailored for the business practices and sectors in which
      they operate.
    </p>
    <h4>8. Testimonials &amp; Public Forums</h4>
    <p>
      We post on our Websites customer testimonials/comments/reviews that may
      contain Personal Information. Prior to posting the testimonial, we obtain
      the customer&rsquo;s consent to post their name along with their
      testimonial. If you want your testimonial removed, please contact us at
      privacy@cactusglobal.com.
    </p>
    <p>
      Our Websites offer publicly accessible blogs, community forums, or
      discussion board posts. You should be aware that any information you
      provide in these areas may be read, collected, and used by others who
      access them. You agree and acknowledge that the use of such information is
      not confidential, does not constitute Personal Information, and is not
      subject to protection under CACTUS&rsquo;s Privacy Policy.
    </p>
    <h4>9. Corporate Events</h4>
    <p>
      In the event CACTUS goes through a business transition, such as a merger
      or acquisition by another company, or sale of all or a portion of its
      assets, your user account and personal data will likely be among the
      assets transferred. You will be notified via a prominent notice of any
      such change in ownership or control. During such event, we may also
      disclose your Personal Information to any other third party with your
      prior consent.
    </p>
    <h4>10. Responsibility with You</h4>
    <p>
      When you submit any Personal Information to us, you represent that you
      have the lawful right to submit the Personal Information and agree that
      you will not submit any information unless you are legally entitled to do
      so and that such Personal Information is true, complete, and accurate. You
      may not use a false email address or other identifying information to
      impersonate any person or entity, or otherwise mislead as to the origin of
      any content.
    </p>
    <p>
      Any improper collection or misuse of Personal Information is in violation
      of this Privacy Policy and of the applicable terms and conditions of use.
      You agree not to misuse any Personal Information available on our Site or
      to gather it or use robots or other automated scripts, codes, or
      functionalities to do so. We may immediately suspend or terminate your
      access to our Site without notice if we become aware that you are in
      breach of applicable terms and conditions of use or of this Privacy
      Policy.
    </p>
    <h4>11. Opting Out and Unsubscribing</h4>
    <p>Reviewing, Correcting, and Deletion of Your Personal Information</p>
    <p>
      Upon request, CACTUS will provide you with information about whether we
      hold any of your Personal Information. We allow users of the Service(s) or
      Website to access, update, or modify their information by clicking on the
      My Account link in the CACTUS navigation menu. If you provide us with your
      Personal Information, you have the following rights with respect to that
      information:
    </p>
    <ul>
      <li>To review the user information that you have supplied to us</li>
      <li>
        To request that we correct any errors, outdated information, or
        omissions in user information that you have supplied to us
      </li>
      <li>To request that your user information not be used to contact you</li>
      <li>To request that your user information be deleted from our records</li>
      <li>
        To withdraw your consent to our use of your information; please note
        that if you withdraw your consent, this will not affect the lawfulness
        of our use and processing of your information on the basis of your
        consent before the point in time when you withdraw your consent.
      </li>
      <li>
        To exercise any of these rights and requests, please contact us at
        privacy @cactusglobal.com or by postal mail to A 603, Satellite Gazebo,
        Guru Hargovindji Marg, Andheri (East), Mumbai 400093, Maharashtra,
        India, Attention: Privacy Team. We will respond to your request within a
        reasonable timeframe and notify you of the action we have taken.
      </li>
    </ul>
    <h5>To Unsubscribe from Our Communications</h5>
    <p>
      Your registered email address will be automatically subscribed to our
      newsletters, which will contain information on industry updates, author
      resources, upcoming webinars, latest offers, and new services by CACTUS.
      You may unsubscribe from our marketing communications by clicking on the
      &quot;unsubscribe&quot; link located on the bottom of our e-mails, or by
      sending us an email at privacy@cactusglobal.com. Customers cannot opt out
      of receiving transactional emails related to their account with us.
    </p>
    <h4>12. Sensitive Personal Information</h4>
    <h5>What Is Sensitive Personal Information?</h5>
    <p>
      &quot;Sensitive Personal Information&quot; is information about an
      individual that reveals their racial or ethnic origin, political opinions,
      religious or philosophical beliefs, trade union membership, genetic
      information, biometric information for the purpose of uniquely identifying
      an individual, information concerning health, or information concerning a
      person&rsquo;s sex life or sexual orientation.
    </p>
    <p>
      We do not knowingly or intentionally collect sensitive Personal
      Information from individuals, and you must not submit sensitive Personal
      Information to us.
    </p>
    <p>
      If, however, you intentionally or unintentionally transmit sensitive
      Personal Information to us, you will be considered to have explicitly
      consented to us processing that sensitive Personal Information.
    </p>
    <h4>13. Amendments to Privacy Policy</h4>
    <p>
      We may update this Privacy Policy from time to time to keep up with
      changing legislation and best practices. If we make any material changes,
      we will notify you by email (sent to the e-mail address specified in your
      account) or by means of a notice on the Website prior to the change
      becoming effective. We will not notify you if we amend the Policy to make
      addition, deletions, or modifications to the list of cookies from time to
      time, to keep the list of cookies current and accurate. Your continued use
      of this Websites or the Service(s) following the posting of any amendment,
      modification, or change to this Policy shall constitute your acceptance of
      the amendments to this Policy. You can choose to discontinue use of the
      Websites or Service(s) if you do not accept the terms of this Policy or
      any modified version of this Policy.
    </p>
    <h4>14. Legal Disclosure</h4>
    <p>
      We reserve the right to disclose your personal data as required by
      applicable law, in response to lawful requests by public authorities,
      including meeting national security or law enforcement requirements and
      when we believe that disclosure is necessary to protect our rights and/or
      to comply with a judicial proceeding, court order, or other legal process
      served on us.
    </p>
    <h4>15. Contact Us</h4>
    <p>
      If you have any questions about this Privacy Policy or the data we hold
      about you or you wish to ensure that the data we hold about you is
      accurate and kept up to date, please do not hesitate to contact us at
      privacy@cactusglobal.com.
    </p>
  </>
);
