import { TocModal } from '../components/modals/toc-modal/toc-modal';
import { useModal } from '../hooks/use-modal';
import { SUPPORT_MAIL } from '@utils/constants';
import { styled } from '../../stitches.config';

const SEP = '\u2002\u2022\u2002';

export const Footer: React.FC = () => {
  const { Modal, openModal } = useModal();
  return (
    <>
      <PageFooter>
        Paperpal is a CACTUS Solution
        {SEP}
        Visit{' '}
        <FooterLink
          href="https://paperpal.com?utm_medium=app_footer&utm_source=paperpal_live_app&utm_campaign=internal"
          target="_blank"
        >
          paperpal.com
        </FooterLink>
        {SEP}
        Please contact{' '}
        <FooterLink href={`mailto:${SUPPORT_MAIL}`}>
          {SUPPORT_MAIL}
        </FooterLink>{' '}
        if you need help
        {SEP}
        <FooterLink as="button" onClick={openModal}>
          Terms and Policies
        </FooterLink>
        {SEP}
        {process.env.NEXT_PUBLIC_VERSION}
      </PageFooter>
      <Modal>
        <TocModal />
      </Modal>
    </>
  );
};

const PageFooter = styled('footer', {
  color: 'rgb(163, 172, 194)',
  fontSize: '12px',
  height: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexShrink: 0,
});

const FooterLink = styled('a', {
  color: 'inherit',
  textDecoration: 'underline',
  display: 'contents',
  cursor: 'pointer',
});
