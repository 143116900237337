import { useDocument } from '@context/document-context';
import { PRICE, WORD_LIMIT } from '@utils/constants';

import { GetFileButton } from './get-file-button';
import { styled } from '../../stitches.config';
import { Spacer } from './spacer';

export const WordLimitFooter: React.FC = () => {
  const { state } = useDocument();
  return (
    <Container
      data-testid="word_limit_footer"
      hasFile={state.context.hasMoreText}
    >
      <Spacer size={48} />
      <Heading>
        {state.context.hasMoreText
          ? `You’ve reached the end of the ${WORD_LIMIT} word preview`
          : 'Get all our edits as tracked changes'}
      </Heading>
      <Spacer size={8} />
      <Message>
        You can download an edited version of your file with suggestions for the
        full document inserted as tracked changes, and with your original
        formatting intact.
      </Message>
      <Spacer size={30} />
      <GetFileButton gaPlace="footer" price={PRICE}>
        Download your file with all edits
      </GetFileButton>
    </Container>
  );
};

const Container = styled('div', {
  variants: {
    hasFile: {
      true: {
        boxShadow: '0 -48px 48px -48px rgba(6, 30, 57, 0.1)',
        marginTop: '-15px',
      },
    },
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  color: '#001f66',
  textAlign: 'center',
  marginTop: 0,
  fontSize: '16px',
  zIndex: 5,
  position: 'relative',
  '& > button': {
    fontSize: '16px',
  },
});

const Heading = styled('h6', {
  fontWeight: 600,
  fontSize: '16px',
});

const Message = styled('p', {
  fontWeight: 400,
});
