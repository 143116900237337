export const Cookies = () => (
  <>
    <h2>Cookies Policy</h2>
    <p>
      CACTUS uses cookies (small text files placed on your device) and similar
      technologies to help you personalize your online experience. Cookies allow
      us to store your preferences and settings (so that you do not have to fill
      in your details every time you visit one of our Company websites / online
      platform); enable you to sign-in; provide interest-based advertising; and
      analyze how our websites and online services are performing. Cookies are
      not used to run programs or deliver viruses to your computer. Cookies are
      uniquely assigned to you, and can only be read by a Web server in the
      domain that issued the cookie to you. One of the primary purposes of
      cookies is to provide a convenience feature to save you time.
    </p>
    <p>
      We and our third-party service providers use technologies such as Web
      Beacons for analyzing trends, administering the website, tracking
      users&rsquo; movements around the site, and gathering demographic
      information about our user base as a whole. We use such information for
      Site traffic analysis. We may receive reports based on the use of these
      technologies by these companies on an individual and aggregated
      basis.&nbsp;
    </p>
    <p>
      We gather certain demographic information automatically and store it in
      log files. This information may include internet protocol (IP) addresses,
      browser type, internet service provider (ISP), referring/exit pages,
      operating system, date/time stamp, and/or clickstream data. This
      information is used by CACTUS to maintain our Service quality to
      understand general statistics regarding use of the Website.
    </p>
    <p>
      You have the ability and a variety of tools to control cookies, web
      beacons and similar technologies, including browser controls to block and
      delete cookies. If you choose to decline cookies, you may not be able to
      fully experience the interactive features of the CACTUS Service or
      Websites you visit. Do Not Track. Do Not Track (&ldquo;DNT&rdquo;) is an
      optional browser setting that allows you to express your preferences
      regarding tracking by advertisers and other third parties. We do not use
      technology that recognizes DNT signals from your web browser.
    </p>
    <p>
      We partner with a third party ad network to either display advertising on
      our Website or to manage our advertising on other sites. Our ad network
      partner uses cookies and Web beacons to collect non-personal information
      about your activities on this and other Web sites to provide you targeted
      advertising based upon your interests. If you wish to not have this
      information used for the purpose of serving you targeted ads, you may
      opt-out by clicking here. Please note this does not opt you out of being
      served advertising. You will continue to receive generic ads.
    </p>
  </>
);
