/* eslint-disable unicorn/no-nested-ternary */
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { Icon } from '@components/icon';

import { MSWordIcon } from '@components/msword-icon';
import { Spinner } from '@components/spinner';
import { useDocument } from '../context/document-context';
import { track, TrackingType } from '../tracking/ga';
import { EditorWorkspace } from '@components/editor-workspace';

import { UploadError } from '@components/upload-error';
import { MAX_FILE_SIZE } from '../utils/constants';
import { styled } from '../../stitches.config';

function getFileName(name?: string): [start: string, end: string] {
  if (!name) {
    return ['', ''];
  }
  const split = name.match(/^(.*)(.docx?)$/i);
  return [split?.[1] || '', split?.[2] || ''];
}

export const UploadPage: React.FC = () => {
  const {
    dropZone: {
      isDragActive,
      isDragReject,
      getRootProps,
      getInputProps,
      open,
      fileRejections,
    },
    state,
    isUploading,
  } = useDocument({
    noKeyboard: true,
    noClick: true,
  });

  if (state.matches('uninitialized')) {
    return null;
  }
  if (isUploading) {
    /**
     * shows when only when we are uploading this is a return to stop dragging
     * and uploading new file while the first one is stilling uploading
     */
    const [start, end] = getFileName(state.context.document?.name);

    return (
      <DropZone>
        <Spinner size={400} speed={4} />
        <InnerContent>
          <MSWordIcon height={90} look={'default'} />
          <FileName data-testid="file_name">
            <Ellipsis>{start}</Ellipsis>
            {end}
          </FileName>
          <HelperText>Uploading your document...</HelperText>
        </InnerContent>
      </DropZone>
    );
  }

  /**
   * shows only when we can't handle the document being uploaded
   * stops the drag and drop
   */
  if (state.hasTag('uploadFailed')) {
    return <UploadError data={state.context} />;
  }

  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].file.size > MAX_FILE_SIZE;

  /**
   * default to showing upload circle
   */

  let content = (
    <StyledDropZone
      data-testid="upload_center"
      onClick={() => {
        track(
          'event',
          TrackingType.Category.uploadPage,
          TrackingType.Actions.uploadIntent,
          TrackingType.Labels.uploadCentre,
        );
        open();
      }}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <input {...getInputProps({})} />
      <Content>
        {isDragReject === true ? (
          <>
            <Icon icon={faTimes} color="#CC0044" size="7x" />
            <HeroText>Unsupported file format</HeroText>
            <UploadButton>Please upload a Microsoft Word file</UploadButton>
          </>
        ) : (
          <>
            <MSWordIcon height={90} look={'default'} />
            <HeroText>Upload your Word file here</HeroText>
            <UploadButton>Click here to upload</UploadButton>
            {isFileTooLarge && (
              <FileToLarge data-testid="file_too_large">
                The file is too large, the limit is 20MB.
              </FileToLarge>
            )}
          </>
        )}
      </Content>
    </StyledDropZone>
  );

  /**
   * show editor when preview text is available, and the user is not trying to upload again
   */
  if (state.context.preview && state.context.sessionId && !isDragActive) {
    content = <EditorWorkspace documentState={state} />;
  }
  return (
    <DropZone
      {...getRootProps({
        onDrop: () => {
          track(
            'event',
            TrackingType.Category.uploadPage,
            TrackingType.Actions.uploadIntent,
            TrackingType.Labels.uploadDrag,
          );
        },
      })}
    >
      {content}
    </DropZone>
  );
};

const DropZone = styled('div', {
  all: 'inherit',
  position: 'relative',
  padding: 0,
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledDropZone = styled('div', {
  variants: {
    isDragActive: {
      true: {
        borderColor: '$mainPrimary',
        backgroundColor: '$secondaryPrimary',
      },
    },
    isDragReject: {
      true: {
        borderColor: '$mainError',
        backgroundColor: '$secondaryError',
      },
    },
  },

  margin: '0px 40px',
  display: 'grid',
  placeItems: 'center',
  height: '40vw',
  width: '40vw',
  maxHeight: '60rem',
  maxWidth: '60rem',
  lineHeight: '2.5rem',
  textAlign: 'center',
  borderWidth: '0.3rem',
  borderStyle: 'dashed',
  borderColor: '#AFB7CA',
  backgroundColor: '#fff',
  borderRadius: '50%',
  outline: 'none',
  transition: '0.1s ease-in-out',
  transitionProperty: 'background-color, border-color',
  '&:hover': {
    borderColor: '#0060ff',
    backgroundColor: '#e6f0ff',
    cursor: 'pointer',
  },
});

const Content = styled('div', {
  gap: '1.6rem',
  display: 'grid',
  placeItems: 'center',
});

const InnerContent = styled(Content, {
  position: 'absolute',
});

const HeroText = styled('h1', {
  color: '$mainText',
  fontSize: '$large',
  fontWeight: 400,
});

const UploadButton = styled('button', {
  border: 'none',
  background: 'none',
  color: 'blue',
  textDecoration: 'underline',
  fontSize: '$regular',
  cursor: 'pointer',
});

const HelperText = styled('span', {
  color: '$secondaryText',
  fontSize: '$regular',
  display: 'block',
});

const FileToLarge = styled('span', {
  color: '$mainError',
  fontSize: '1.2rem',
});

const FileName = styled(HeroText, {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '800px',
});
const Ellipsis = styled('div', {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '3rem',
});
